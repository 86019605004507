import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  query,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

interface Props {}

function YuvaDabazoDetails(props: Props) {
  const {} = props;
  const firestore = useFirestore();
  const navigate = useNavigate();
  const { id } = useParams();
  const [recordDetails, setRecordDetails] = useState<DocumentData | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getSpecificRecord = async () => {
    setIsLoading(true);
    try {
      const docRef = doc(firestore, "yuvadabazo", id!);

      const docSnap = await getDoc(docRef);

      setIsLoading(false);
      if (docSnap.exists()) {
        // Document data is available here
        console.log("Document data:", docSnap.data());
        setRecordDetails(docSnap.data());
        return docSnap.data(); // Return the document data
      } else {
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      setIsLoading(false);
      return null;
    }
  };

  useEffect(() => {
    getSpecificRecord();
  }, []);

  function loading() {
    return (
      <Box
        height={300}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* <Loading /> */}
      </Box>
    );
  }

  function listItem({
    title,
    value,
  }: {
    title: string;
    value: any;
  }): JSX.Element {
    return (
      <Box>
        <Typography color="primary.main">{title}</Typography>
        <Typography>{value || "-"}</Typography>
      </Box>
    );
  }

  return (
    <MainLayout>
      <Grid container spacing={3}>
        <Grid item md={8} lg={7} sm={12} xs={12}>
          <Card sx={{ width: "100%", borderRadius: 2, my: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="row">
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography
                    variant={isMobile ? "h6" : "h6"}
                    fontWeight="600"
                    paddingLeft={1}
                  >
                    Parish Details
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                ></Box>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box m={2}>
                {isLoading ? (
                  loading()
                ) : (
                  <Box overflow="auto" flex={1}>
                    <Grid container spacing={3}>
                      <Grid item md={6} lg={6} sm={12} xs={12}>
                        {listItem({
                          title: "Deanery Name",
                          value: recordDetails?.deaneryName?.name,
                        })}
                      </Grid>
                      <Grid item md={6} lg={6} sm={12} xs={12}>
                        {listItem({
                          title: "Parish Name",
                          value: recordDetails?.parishName?.value,
                        })}
                      </Grid>
                      <Grid item md={6} lg={6} sm={12} xs={12}>
                        {listItem({
                          title: "President Name",
                          value: recordDetails?.presidentName,
                        })}
                      </Grid>
                      <Grid item md={6} lg={6} sm={12} xs={12}>
                        {listItem({
                          title: "Animators Name",
                          value: recordDetails?.animatorName,
                        })}
                      </Grid>
                      <Grid item md={6} lg={6} sm={12} xs={12}>
                        {listItem({
                          title: "Contact number",
                          value: recordDetails?.contactNumber,
                        })}
                      </Grid>
                      <Grid item md={6} lg={6} sm={12} xs={12}>
                        {listItem({
                          title: "Attendees Count",
                          value: recordDetails?.others,
                        })}
                      </Grid>

                      <Grid item md={6} lg={6} sm={12} xs={12}>
                        <Typography color="primary.main">
                          {"Registred events"}
                        </Typography>
                        {recordDetails?.participatingEventList?.length ? (
                          <Box display="flex" flexDirection={"column"}>
                            {recordDetails?.participatingEventList?.map(
                              (item: { name: string; id: number }) => {
                                return (
                                  <Typography textTransform={"capitalize"}>
                                    {item?.name}
                                  </Typography>
                                );
                              }
                            )}
                          </Box>
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </Grid>
                      {recordDetails?.participatingEventList?.filter(
                        (item: { id: number }) => item?.id === 1
                      )?.length ? (
                        <>
                          <Grid item md={12} lg={12} sm={12} xs={12}>
                            <Typography
                              color="primary.main"
                              variant="h6"
                              fontWeight={500}
                            >
                              Creative Walk
                            </Typography>
                          </Grid>
                          {recordDetails?.creativeWalkPartipantsName?.map(
                            (item: { name: string; id: number }) => {
                              return (
                                <Grid item md={6} lg={6} sm={12} xs={12}>
                                  <Typography color="primary.main">
                                    {`Participant Name ${item?.id}`}
                                  </Typography>
                                  <Typography>{item?.name || "-"}</Typography>
                                </Grid>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {recordDetails?.participatingEventList?.filter(
                        (item: { id: number }) => item?.id === 2
                      )?.length ? (
                        <>
                          <Grid item md={12} lg={12} sm={12} xs={12}>
                            <Typography
                              color="primary.main"
                              variant="h6"
                              fontWeight={500}
                            >
                              Flower carpet
                            </Typography>
                          </Grid>
                          {recordDetails?.flowerCarpetParticipantName?.map(
                            (item: { name: string; id: number }) => {
                              return (
                                <Grid item md={6} lg={6} sm={12} xs={12}>
                                  <Typography color="primary.main">
                                    {`Participant Name ${item?.id}`}
                                  </Typography>
                                  <Typography>{item?.name || "-"}</Typography>
                                </Grid>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {recordDetails?.participatingEventList?.filter(
                        (item: { id: number }) => item?.id === 4
                      )?.length ? (
                        <>
                          <Grid item md={12} lg={12} sm={12} xs={12}>
                            <Typography
                              color="primary.main"
                              variant="h6"
                              fontWeight={500}
                            >
                              Futuristic Art
                            </Typography>
                          </Grid>
                          <Grid item md={6} lg={6} sm={12} xs={12}>
                            <Typography color="primary.main">
                              {`Participant name`}
                            </Typography>
                            <Typography>
                              {recordDetails?.futuristicArtParticipantName ||
                                "-"}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {recordDetails?.participatingEventList?.filter(
                        (item: { id: number }) => item?.id === 3
                      )?.length ? (
                        <>
                          <Grid item md={12} lg={12} sm={12} xs={12}>
                            <Typography
                              color="primary.main"
                              variant="h6"
                              fontWeight={500}
                            >
                              Brain Wave
                            </Typography>
                          </Grid>
                          {recordDetails?.brainWaveParticipantName?.map(
                            (item: { name: string; id: number }) => {
                              return (
                                <Grid item md={6} lg={6} sm={12} xs={12}>
                                  <Typography color="primary.main">
                                    {`Participant Name ${item?.id}`}
                                  </Typography>
                                  <Typography>{item?.name || "-"}</Typography>
                                </Grid>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {/* {recordDetails?.participatingEventList?.filter(
                        (item: { id: number }) => item?.id === 5
                      )?.length ? (
                        <>
                          <Grid item md={12} lg={12} sm={12} xs={12}>
                            <Typography
                              color="primary.main"
                              variant="h6"
                              fontWeight={500}
                            >
                              Other Attendees
                            </Typography>
                          </Grid>
                          <Grid item md={6} lg={6} sm={12} xs={12}>
                            <Typography color="primary.main">
                              {`Attendees Count`}
                            </Typography>
                            <Typography>
                              {recordDetails?.others || "-"}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )} */}
                    </Grid>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
}

export default YuvaDabazoDetails;
