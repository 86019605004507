import { Menu } from "@mui/icons-material";
import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import Drawer from "./drawer";
import Breadcrumb from "../../components/breadcrumbs/breadcrubm";

interface AdminDashboardLayoutProps {}

function AdminDashboardLayout({
  children,
}: PropsWithChildren<AdminDashboardLayoutProps>) {
  return (
    <Box
      display={"flex"}
      flex={1}
      height={"100vh"}
      flexDirection={"column"}
      sx={{ backgroundColor: "#DEEFF5" }}
    >
      <Box
        display={"flex"}
        height={70}
        bgcolor={"white"}
        pl={3}
        sx={{ borderBottom: "0.1px solid #808080" }}
      >
        <IconButton>
          <Menu />
        </IconButton>
        <Typography fontWeight={600} mt={2}>
          Youth Commission Udupi Admin
        </Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid
            item
            md={2}
            xl={2}
            lg={2}
            // sm={0}
            // xs={0}
            bgcolor={"white"}
          >
            <Box height={"90vh"}>
              <Drawer />
            </Box>
          </Grid>
          <Grid item md={10} xl={10} lg={10} sm={12} xs={12}>
            <Box m={2} height={"100%"}>
              <Card
                sx={{
                  height: "95%",
                  borderRadius: 2,
                }}
              >
                <Box ml={3}>
                  <Breadcrumb />
                </Box>
                <Typography>{children}</Typography>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AdminDashboardLayout;
