import React from "react";
import MainLayout from "../../../layouts/main_layout/main_layout";
import { Box, Grid, Typography } from "@mui/material";
import { SECONDARY_THEME_COLOR } from "../../../providers/theme/colors/colors";
import { useNavigate } from "react-router-dom";
import { ArrowRightAlt } from "@mui/icons-material";

interface Props {}

function RegistrationDone(props: Props) {
  const navigate = useNavigate();
  const {} = props;

  return (
    <MainLayout>
      <Box mb={4}>
        <Box mt={3}>
          <Typography textAlign={"center"} variant="h5" fontWeight={600}>
            Congratulations !!!
          </Typography>
        </Box>
        <Box mt={2}>
          <Grid container spacing={4} mt={0}>
            <Grid item md={6} lg={6} sm={12}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                height={"100%"}
                justifyContent={"center"}
              >
                <Typography variant="body1" mb={2}>
                  You have successfully registered for Yuva Dabazo 2024. We are
                  excited to have you join us for a day filled with energy,
                  creativity, and inspiration. Get ready to participate in
                  thrilling competitions, engaging activities, and connect with
                  youth from across the diocese.
                </Typography>
                <Typography variant="body1" mb={2}>
                  Join us for the grand diocesan youth convention,{" "}
                  <strong>Yuva Dabazo 2024</strong>, on{" "}
                  <strong>September 29, 2024</strong>, starting at{" "}
                  <strong>8:00 AM</strong> at the beautiful{" "}
                  <strong>St. Francis Xavier Church, Udyavar</strong>. This
                  vibrant event is set to bring together young minds from across
                  the diocese for a day of creativity, competition, and
                  camaraderie.
                </Typography>

                <Box component="ul" pl={2} mb={2}>
                  <Typography component="li" variant="body1" mb={1}>
                    <strong>Unit-Wise Competitions</strong>: Showcase your
                    talent in exciting categories such as Flower Carpet,
                    Futuristic Art, Brain Wave, and Creative Walk.
                  </Typography>
                  <Typography component="li" variant="body1" mb={1}>
                    <strong>Deanery Level Competition</strong>: The Battle of
                    Bands promises to be an electrifying showdown you won't want
                    to miss.
                  </Typography>
                </Box>

                <Typography variant="body1" mb={2}>
                  Don't miss out on this incredible opportunity to connect,
                  compete, and celebrate with youth from all around the diocese!
                </Typography>
              </Box>
            </Grid>
            <Grid display={"flex"} item md={6} lg={6} sm={12} xs={12}>
              <Box
                display={"flex"}
                height="100%"
                width={"100%"}
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                <Box
                  component="img"
                  src="/images/yuvadabazo.jpeg"
                  alt=""
                  sx={{
                    borderRadius: 2,
                    width: { xs: "100%", sm: "100%", md: "100%", lg: 400 },
                    height: { xs: "auto", sm: "auto", md: "auto", lg: 500 },
                    maxHeight: { xs: "none", md: "100%" },
                    maxWidth: { xs: "none", md: "100%" },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </MainLayout>
  );
}

export default RegistrationDone;
