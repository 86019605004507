import {
  AnalyticsOutlined,
  Menu,
  Newspaper,
  People,
  PeopleOutlineOutlined,
  Reviews,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {}

function Drawer(props: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {} = props;

  let dashboardItems = [
    {
      id: 1,
      name: "Dashboard",
      path: "/admin/home",
      activeIcon: <AnalyticsOutlined sx={{ pl: 1, color: "#ffffff" }} />,
      inActiveicon: <AnalyticsOutlined sx={{ pl: 1, color: "#565564" }} />,
    },
    {
      id: 2,
      name: "Users",
      path: "/admin/users",
      activeIcon: <People sx={{ pl: 1, color: "#ffffff" }} />,
      inActiveicon: <PeopleOutlineOutlined sx={{ pl: 1, color: "#565564" }} />,
    },
    {
      id: 3,
      name: "Upload News",
      path: "/admin/uploadNews",
      activeIcon: <Newspaper sx={{ pl: 1, color: "#ffffff" }} />,
      inActiveicon: <Newspaper sx={{ pl: 1, color: "#565564" }} />,
    },
    {
      id: 4,
      name: "Rview Section",
      path: "/admin/reviews",
      activeIcon: <Reviews sx={{ pl: 1, color: "#ffffff" }} />,
      inActiveicon: <Reviews sx={{ pl: 1, color: "#565564" }} />,
    },
  ];

  return (
    <Box display={"flex"} flexDirection={"column"} mx={2} mt={2}>
      {dashboardItems?.map((item) => {
        return (
          <Button
            onClick={() => {
              navigate(item?.path);
            }}
            sx={{
              my: 1,
              py: 1,
              backgroundColor:
                pathname === item?.path ? "#0d0c22" : "transparent",
              borderRadius: 10,
              ":hover": {
                borderRadius: 10,
                border: "0.1px solid #808080",
              },
              justifyContent: "start",
            }}
          >
            {pathname === item?.path ? item?.activeIcon : item?.inActiveicon}
            <Typography
              textAlign={"start"}
              variant="subtitle2"
              color={pathname === item?.path ? "#ffffff" : "#565564"}
              ml={1}
            >
              {item?.name}
            </Typography>
          </Button>
        );
      })}
    </Box>
  );
}

export default Drawer;
