import React from "react";
import AdminDashboardLayout from "../../../layouts/admin_dashboard_layout/admin_dashboard_layout";
import { Box, Button, IconButton, Typography } from "@mui/material";
import DataGrid from "./component/data_grid/data_grid";
import {
  ArrowBackIosNewOutlined,
  ArrowBackOutlined,
  BackHandOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { collection, query } from "firebase/firestore";
import { useAuth, useFirestore, useFirestoreCollectionData } from "reactfire";

interface Props {}

function Users(props: Props) {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const auth = useAuth();

  const {} = props;

  const usersLabel: { label: string }[] = [
    {
      label: "Sl. No.",
    },
    {
      label: "Full Name",
    },
    {
      label: "Email ID",
    },
    {
      label: "Role Name",
    },
    {
      label: "Created At",
    },
  ];

  const collectionData = collection(firestore, "admins");
  const chapterCollectionQuery = query(
    collectionData
    // orderBy("order", "asc")
  );

  const {
    status,
    data: admins,
    hasEmitted,
  } = useFirestoreCollectionData(chapterCollectionQuery);

  console.log(auth.currentUser);

  return (
    <AdminDashboardLayout>
      <Box flex={1} mt={2} mx={2}>
        <Box display={"flex"} mb={2} justifyContent={"space-between"}>
          <Box display={"flex"}>
            <IconButton>
              <ArrowBackOutlined />
            </IconButton>
            <Typography ml={1} mt={1} variant="subtitle2">
              Admins
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={() => navigate("/admin/users/createAdmin")}
              variant="outlined"
              sx={{ color: "#000000", minWidth: 200 }}
            >
              <Typography variant="body2">Create User</Typography>
            </Button>
          </Box>
        </Box>
        <DataGrid labels={usersLabel} data={admins || []} />
      </Box>
    </AdminDashboardLayout>
  );
}

export default Users;
