import { Box, Typography } from "@mui/material";
import React from "react";
import MainLayout from "../../layouts/main_layout/main_layout";

export default function Profile() {
  return (
    <MainLayout>
      <Typography>Profile</Typography>
    </MainLayout>
  );
}
