import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import { DownloadOutlined } from "@mui/icons-material";
import { MAIN_THEME_COLOR } from "../../providers/theme/colors/colors";
import {
  collection,
  DocumentData,
  getDocs,
  getFirestore,
} from "firebase/firestore";

interface Props {}

function YuvaGaaz(props: Props) {
  const [files, setFiles] = useState<DocumentData>([]);
  const firestore = getFirestore();

  useEffect(() => {
    const fetchFiles = async () => {
      const filesCollection = collection(firestore, "yuvagaaz");
      const filesSnapshot = await getDocs(filesCollection);
      const filesList = filesSnapshot.docs.map((doc) => doc.data());
      setFiles(filesList);
    };

    fetchFiles();
  }, [firestore]);

  return (
    <MainLayout>
      <Box my={4}>
        <Typography variant="h5" fontWeight={"600"} textAlign={"center"}>
          Yuva Gaaz
        </Typography>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          mt={3}
          container
          spacing={3}
        >
          {files?.[0]?.yuvagaazList?.map(
            (item: { id: number; path: string; year: string }) => {
              return (
                <Grid item lg={3} md={3} sm={12} xs={12} key={item.id}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    px={2}
                    py={2}
                    sx={{
                      border: `1px solid ${MAIN_THEME_COLOR}`,
                      borderRadius: 1,
                      borderBottom: `3px solid ${MAIN_THEME_COLOR}`,
                    }}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <a
                      href={
                        "https://firebasestorage.googleapis.com/v0/b/youth-commission-udupi.appspot.com/o/YUVAGAZ.pdf?alt=media&token=ac28a06e-7240-4a0e-80b6-12d6d5321464"
                      }
                      download
                      target="_blank"
                    >
                      <Typography fontWeight={600} mr={2}>
                        {"Yuva Gaaz" + " " + item?.year}
                      </Typography>
                    </a>
                    <DownloadOutlined style={{ height: 20, width: 20 }} />
                  </Box>
                </Grid>
              );
            }
          )}
        </Grid>
      </Box>
    </MainLayout>
  );
}

export default YuvaGaaz;
