import { Box, Typography } from "@mui/material";
import React from "react";
import AdminDashboardLayout from "../../../layouts/admin_dashboard_layout/admin_dashboard_layout";

interface Props {}

function Dashboard(props: Props) {
  const {} = props;

  return (
    <AdminDashboardLayout>
      <Box>
        <Typography>Home</Typography>
      </Box>
    </AdminDashboardLayout>
  );
}

export default Dashboard;
