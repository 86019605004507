import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import AdminDashboardLayout from "../../../layouts/admin_dashboard_layout/admin_dashboard_layout";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ref } from "firebase/storage";
import { getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { firestore, storage } from "../../..";

interface Props {}

function UploadNews() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleUpload = async () => {
    setUploading(true);
    try {
      const promises = selectedImages?.map(async (image) => {
        console.log("inside");

        const storageRef = ref(storage, `images/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);

        await uploadTask;

        const downloadURL = await getDownloadURL(storageRef);
        await addDoc(collection(firestore, "images"), {
          name: image.name,
          url: downloadURL,
          createdAt: new Date(),
        });
      });

      await Promise.all(promises!);
    } catch (error) {
      console.error("Error uploading images: ", error);
    } finally {
      setUploading(false);
      setSelectedImages([]);
    }
  };

  return (
    <AdminDashboardLayout>
      <Grid container>
        <Grid item md={6} lg={6} xl={6}>
          <Card sx={{ width: "100%", borderRadius: 4, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="column" pl={1}>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600">
                    Add New News
                  </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Box>
                    <TextField
                      fullWidth
                      label="Title of the news"
                      placeholder="Enter news title"
                      value={title}
                      onChange={(event) => {
                        setTitle(event?.target.value);
                      }}
                      required
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      rows={8}
                      multiline
                      fullWidth
                      label="Description of the news"
                      placeholder="Enter news Description"
                      value={description}
                      onChange={(event) => {
                        setDescription(event?.target.value);
                      }}
                      FormHelperTextProps={{ sx: { color: "red" } }}
                      required
                    />
                  </Box>

                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files != null) {
                        // Convert FileList to an array of File objects
                        const filesArray = Array.from(e.target.files);
                        setSelectedImages((prevImages) => [
                          ...prevImages,
                          ...filesArray,
                        ]);
                      }
                    }}
                  />

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={4}
                  >
                    <Button
                      onClick={() => navigate("/admin/users")}
                      variant="outlined"
                      sx={{
                        minWidth: 200,
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={() => {
                        handleUpload();
                      }}
                      sx={{
                        minWidth: 200,
                      }}
                      variant="contained"
                    >
                      Create Post
                    </Button>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AdminDashboardLayout>
  );
}

export default UploadNews;
