import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { MAIN_THEME_COLOR } from "../../../../../providers/theme/colors/colors";
import { DocumentData } from "firebase/firestore";

interface DataGridInterface {
  labels: { label: string }[];
  data: DocumentData;
}

function DataGrid({ labels, data }: DataGridInterface) {
  function gridTypography({ text }: { text: string | number }) {
    return (
      <Box
        display="flex"
        flex={1}
        style={{
          overflow: "hidden",
        }}
      >
        <Typography fontSize={13} textOverflow={"ellipsis"} overflow="hidden">
          {text ? text : "-"}
        </Typography>
      </Box>
    );
  }
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric", // Numeric day of the month (e.g., "14")
    month: "short", // Short month name (e.g., "Aug")
    year: "numeric", // Full numeric year (e.g., "2024")
  };

  return (
    <Box display="flex" flex={1} flexDirection={"column"} width={"100%"}>
      <Grid container direction="row" p={2}>
        {labels.map((item, index) => (
          <Grid item flex={1}>
            <Typography fontWeight={600} color={MAIN_THEME_COLOR} fontSize={13}>
              {item.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {data?.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <Typography>No Data Available</Typography>
        </Box>
      ) : (
        <Grid container direction="row" mt={3}>
          {data?.map((item: any, index: number) => {
            let date = new Date(item?.updatedAt);
            const formattedDate = new Intl.DateTimeFormat(
              "en-GB",
              options
            ).format(date);

            return (
              <Box
                key={index}
                display={"flex"}
                flexDirection={"column"}
                width="100%"
              >
                <Divider
                  sx={{
                    display: "flex",
                    opacity: 0.5,
                    borderBottom: "1px dotted #7C797C",
                    mx: 2,
                  }}
                />
                <Grid
                  display="flex"
                  item
                  flex={1}
                  flexDirection={"row"}
                  my={3}
                  px={2}
                >
                  {gridTypography({ text: index + 1 })}
                  {gridTypography({ text: item?.name })}
                  {gridTypography({ text: item?.email_id })}
                  {gridTypography({ text: item?.role })}
                  {gridTypography({
                    text: formattedDate,
                  })}
                </Grid>
              </Box>
            );
          })}
        </Grid>
      )}
    </Box>
  );
}

export default DataGrid;
