import { Box, Snackbar, SnackbarContent, Typography } from "@mui/material";
import React, {
  useContext,
  useState,
  createContext,
  FC,
  ReactNode,
} from "react";

interface LayoutProps {
  children: ReactNode;
}
interface SnackbarContextType {
  showSnackbar: (title: string, color?: string) => void;
}
const FeedbackContext = createContext<SnackbarContextType | undefined>(
  undefined
);

const FeedbackProvide: FC<LayoutProps> = ({ children }) => {
  const [showSnackbarMsg, setShowSnackbar] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [colorCode, setColorCode] = useState<string>("");
  const handleClose = () => {
    setShowSnackbar(false);
  };

  const showSnackbar = (title: string, color?: string) => {
    console.log(title);
    setTitle(title);
    setShowSnackbar(true);
    setColorCode(color || "#1B001B");
  };

  return (
    <FeedbackContext.Provider value={{ showSnackbar }}>
      {children}
      <Box display={"flex"}>
        <Snackbar
          open={showSnackbarMsg}
          autoHideDuration={4000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ mt: 10 }}
        >
          <SnackbarContent
            message={
              <Typography
                component="div"
                sx={{
                  textAlign: "center",
                  whiteSpace: "pre-line", // Allows breaking into multiple lines
                }}
              >
                {title}
              </Typography>
            }
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colorCode,
            }}
          />
        </Snackbar>
      </Box>
    </FeedbackContext.Provider>
  );
};

export function useFeedback() {
  const context = useContext(FeedbackContext);
  if (!context) {
    throw new Error("useFeedback must be used within a FeedbackProvider");
  }
  return context;
}

export default FeedbackProvide;
