import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MainLayout from "../../../../layouts/main_layout/main_layout";
import AdminDashboardLayout from "../../../../layouts/admin_dashboard_layout/admin_dashboard_layout";
import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { useAnalytics, useAuth, useFirestore } from "reactfire";
import { useFeedback } from "../../../../providers/feedback/feedback";
import { logEvent } from "firebase/analytics";
import {
  DocumentData,
  Timestamp,
  addDoc,
  collection,
} from "firebase/firestore";

interface Props {}

function CreateAdmin() {
  const [adminName, setAdminName] = useState("");
  const [adminEmailId, setAdminEmailId] = useState("");
  const [error, setError] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const firestore = useFirestore();
  const { showSnackbar } = useFeedback();
  const auth = useAuth();
  const analytics = useAnalytics();

  const submitForm = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!emailRegex?.test(adminEmailId)) {
      setErrorMsg({ ...errorMsg, email: "Please enter valid email" });
    } else if (password.length < 6) {
      setErrorMsg({
        ...errorMsg,
        password: "Password should be at least 6 characters",
      });
    } else {
      createUserWithEmailAndPassword(auth, adminEmailId, password)
        .then(async (userCredential) => {
          setIsLoading(false);
          logEvent(analytics, "signin", { uid: userCredential?.user.uid });
          const user = userCredential.user;
          const usersCollection = collection(firestore, "admins");
          await addDoc(usersCollection, {
            createdAt: Date().toString(),
            updatedAt: Date().toString(),
            email_id: adminEmailId,
            name: adminName,
            role: "Admin",
            role_id: "admin",
            uid: userCredential?.user?.uid,
          });
          showSnackbar("User Added Successfully");
          //   navigate("/admin/users");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
          if (errorCode === "auth/invalid-credential") {
            // showSnackbar("You are not registered with us. Please sign up.");
            showSnackbar("Please enter valid credentials");
          }
          if (errorCode === "auth/weak-password") {
            setErrorMsg({
              ...error,
              password: "Password should be at least 6 characters",
            });
          }
          setIsLoading(false);
        });
    }
  };

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }
  return (
    <AdminDashboardLayout>
      <Grid container>
        <Grid item md={6} lg={6} xl={6}>
          <Card sx={{ width: "100%", borderRadius: 4, m: 1 }} elevation={1}>
            <CardContent>
              <Box display="flex" flexDirection="column" pl={1}>
                <Box flexDirection="row" display="flex" alignItems="center">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack></ArrowBack>
                  </IconButton>
                  <Typography variant="h6" fontWeight="600">
                    Add New Admin
                  </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                  <Box>
                    <TextField
                      fullWidth
                      label="Admin Name"
                      placeholder="Enter Admin name"
                      value={adminName}
                      onChange={(event) => {
                        setAdminName(event?.target.value);
                      }}
                      required
                    />
                  </Box>
                  <Box mt={3}>
                    <TextField
                      fullWidth
                      label="Email ID"
                      placeholder="Enter Email id"
                      value={adminEmailId}
                      onChange={(event) => {
                        setAdminEmailId(event?.target.value);
                      }}
                      FormHelperTextProps={{ sx: { color: "red" } }}
                      helperText={errorMsg?.email}
                      required
                    />

                    <Box mt={2}>
                      <TextField
                        placeholder="Enter  password"
                        fullWidth
                        value={password}
                        sx={{ mt: 1 }}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setErrorMsg({ ...errorMsg, password: "" });
                        }}
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        helperText={errorMsg?.password}
                        FormHelperTextProps={{ sx: { color: "red" } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    mt={4}
                  >
                    <Button
                      onClick={() => navigate("/admin/users")}
                      variant="outlined"
                      sx={{
                        minWidth: 200,
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={submitForm}
                      sx={{
                        minWidth: 200,
                      }}
                      variant="contained"
                    >
                      Add Admin
                    </Button>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AdminDashboardLayout>
  );
}

export default CreateAdmin;
