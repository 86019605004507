import React from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import Calender from "../home/components/calender/calender";

interface Props {}

function EventCalender(props: Props) {
  const {} = props;

  return (
    <MainLayout>
      <Calender />
    </MainLayout>
  );
}

export default EventCalender;
